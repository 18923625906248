import { template as template_20d25cdb64554a558ab1e0061c1c3036 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_20d25cdb64554a558ab1e0061c1c3036(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
