import { template as template_e0e77916e52a439581bc073d93211dbd } from "@ember/template-compiler";
const SidebarSectionMessage = template_e0e77916e52a439581bc073d93211dbd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
