import { template as template_a22962b9e84b45c6a8b143ff8605edc8 } from "@ember/template-compiler";
const FKLabel = template_a22962b9e84b45c6a8b143ff8605edc8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
